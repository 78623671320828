/**
 * Author:    Christopher Bailey
 * Created:   02.24.2022
 * Filename: /actions/index.js
 * Purpose: Will eventually combine all dispatch actions into a single location by importing them from different files and merging. Currently,
 * this is just the current actions for logging in and updating user tokens.
 * (c) Copyright by PDG+Creative.
 **/

import axios from 'axios';
import { userConstants } from'../actions/userTypes';

export const postLoginUser = payload => dispatch => {
    dispatch({ type: userConstants.LOGIN_START });

    axios
    .post(`${process.env.REACT_APP_LOGIN}`, payload.credentials)
    .then(res => {
        const token = res.data.token;
        const user = res.data.user;

        // Store the token in local storage
        localStorage.setItem('token', token);

        dispatch({ 
            type: userConstants.LOGIN_SUCCESS, 
            payload: { user, token } // Include both user and token in the payload
        });
        axios.put(process.env.REACT_APP_SET_STRIPE_CUSTOMER, {email: user.email})
        return res.data; // Pass the user data down to the promise chain
    })
    .catch(err => {
        alert("Incorrect Credentials.");
        dispatch({ type: userConstants.LOGIN_FAIL, payload: err });
    });

};

// Action to authenticate user with token
export const authenticateUserWithToken = (token) => async (dispatch) => {
    try {
      // Make a request to the server to verify the token
      const response = await axios.post(process.env.REACT_APP_AUTHENTICATION, {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the request header
        },
      });
  
      if (response.status === 200) {
        const { token: newToken, user } = response.data;
        // Store the new token in local storage
        localStorage.setItem('token', newToken);
  
        // Dispatch success action with the new token and user information
        dispatch({ 
          type: userConstants.STAY_LOGGED_IN, 
          payload: { user, token: newToken } // Include both user and token in the payload
        });
    
      } else {  
        // Token is invalid or expired, remove token from localStorage and log user out
        localStorage.removeItem('token');
        dispatch({ type: userConstants.LOGOUT_USER });
      }
    } catch (error) {
      console.error('Error authenticating user with token:', error);
      
      // Handle error, for example, show an error message to the user
      alert("Incorrect Credentials.");
      
      // Remove token from localStorage and log user out in case of error
      localStorage.removeItem('token');
      dispatch({ type: userConstants.LOGOUT_USER });
    }
  };



export const postForgotPassword = payload => dispatch =>{
    dispatch({type: userConstants.FORGOT_PASSWORD_START, payload: payload })
    axios
    .post(`${process.env.REACT_APP_FORGOT_PASS}`, payload.credentials)
    .then(res =>{
        if(res === null) {
            alert("No email found.")
            dispatch({ type: userConstants.FORGOT_PASSWORD_SUCCESS, payload: res.data });
        }
        else {
            alert('email sent')
            dispatch({ type: userConstants.FORGOT_PASSWORD_SUCCESS, payload: res.data });
        }
    })
    .catch(err => {
        alert('Check your email for a password link. \n\nIf you do not see this email, check your spam folder. \n\nTo avoid emails going to your spam folder, add support@mylevlr.com to your safe senders list.')
        dispatch({ type: userConstants.FORGOT_PASSWORD_FAILURE, payload: err})
    }
    )
}

export const postRegisterUser = payload => dispatch => {
    dispatch({ type: userConstants.REGISTER_START })
    console.log("payload: " + JSON.stringify(payload,null,2))
    axios
    .post(`${process.env.REACT_APP_REGISTER}`, payload.credentials)
    .then(res => {
        dispatch({ type: userConstants.REGISTER_SUCCESS, payload: payload });
        alert("Sign up successful.")
    })
    .catch(err => dispatch({ type: userConstants.REGISTER_FAIL, payload:err }));
  
};

export const putUpdateUser = payload => dispatch => {
    dispatch({ type: userConstants.REGISTER_START })
    axios
    .put(`${process.env.REACT_APP_UP_USER}`, payload.credentials)
    .then(res => {
        dispatch({ type: userConstants.UPDATE_USER_SUCCESS, payload: payload });
    })
    .catch(err => dispatch({ type: userConstants.UPDATE_USER_FAIL, payload:err }));

};

export const updateToken = token => dispatch => {
    dispatch({ type: userConstants.UPDATE_TOKEN, payload: { token } });
};



export const logoutUser = payload => dispatch => {
    dispatch({ type: userConstants.LOGOUT_USER, payload: payload });
    payload.history.push('/login');
}


export const getActiveUsers = () => dispatch => {
    //console.log('dispatch?')
    dispatch({ type: userConstants.FETCH_ACTIVE_USERS })
        axios.get(`${process.env.REACT_APP_ACTIVE_USR}`)
            .then(res =>{
                //console.log(res.data, '<- Data in fetch dispatch')
                dispatch({
                    type: userConstants.FETCH_ACTIVE_SUCCESS,
                    payload: res.data
                })
            })
            .catch(err => {
               
                dispatch({
                    type: userConstants.FETCH_ACTIVE_FAIL,
                    error: err.response.data.message
                })
            })
}

export const getInactiveUsers = () => dispatch => {
    //console.log('dispatch?')
    dispatch({ type: userConstants.FETCH_INACTIVE_USERS })
        axios.get(`${process.env.REACT_APP_INACTIVE_USR}`)
            .then(res =>{
                //console.log(res.data)
                dispatch({
                    type: userConstants.FETCH_INACTIVE_SUCCESS,
                    payload: res.data
                })
            })
            .catch(err => {
               
                dispatch({
                    type: userConstants.FETCH_INACTIVE_FAIL,
                    error: err.response.data.message
                })
            })
}

/* 
export const activateUser = payload => dispatch => {
    dispatch({ type: userConstants.ACTIVATE_USER_START })
    axios
    .put(`${process.env.REACT_APP_ACTIVATE}`, payload.email)
    .then(res, err => {
        dispatch({ type: userConstants.ACTIVATE_USER_SUCCESS, payload: payload });
    })
    .catch(err => dispatch({ type: userConstants.ACTIVATE_USER_FAIL, payload:err }));

};

export const deactivateUser = payload => dispatch => {
    dispatch({ type: userConstants.DEACTIVATE_USER_START })
    axios
    .put(`${process.env.REACT_APP_DEACTIVATE}`, payload.email)
    .then(res => {
        dispatch({ type: userConstants.DEACTIVATE_USER_SUCCESS, payload: res.data });
    })
    .catch(err => 
        dispatch({
             type: userConstants.DEACTIVATE_USER_FAIL, 
             payload: err }));
   
}; */

export const putUpdatePassword = payload => dispatch => {
    dispatch({ type: userConstants.UPDATE_PASSWORD_START })
    axios
    .put(`${process.env.REACT_APP_UPDATE_PASS}`, payload.credentials)
    .then(res => {
        alert('Your password has been successfully updated.')
        dispatch({ type: userConstants.UPDATE_PASSWORD_SUCCESS, payload: payload })
    })
    .catch(err => {
        alert('Failed updating your password')        
        dispatch({ type: userConstants.UPDATE_PASSWORD_FAILURE, payload:err })
    });
};