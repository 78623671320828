import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postForgotPassword } from '../../actions/auth';
import validator from 'validator';

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'

import backgroundImage from "../../img/LEVLR-Login-Background.jpg";
import levlr_logo from "../../img/LEVLR Logo_CMYK.png";


const ForgotPassword = props => {
  
  const [credentials, setCredentials] = useState({})
  const [response, setResponse] = useState({})
  const [validEmail, setValidEmail] = useState(false)

  

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const backGroundStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    paddingBottom: '10%',
    paddingTop: '10%'
}

const loginPaperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    width: '70%',
    height: 'auto',
    paddingTop: '4vh',
    paddingBottom: '4vh',
    marginLeft: 'auto',
    marginRight: 'auto'
}

const loginTextFieldStyle = {
    width: '65%',
    height: 'auto'
}

const loginFormStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 1
}

const loginButtonStyle = {
    width: '50%',
    color: "white",
    backgroundColor: "#2fb7b7",
    '&:hover': {
        backgroundColor: '#01656a'
    },
    margin: 16
}

const signupButtonStyle = {
    width: '50%',
    color: "white",
    backgroundColor: "#01656a",
    '&:hover': {
        backgroundColor: '#2fb7b7'
    },
    margin: 16
}

const levlrLogoStyle = {
    width: '40%',
    height: 'auto'
}

  const showNullError = useSelector(state => state.showNullError)
  const showError = useSelector(state => state.showError)
  const serverMsg = useSelector(state => state.msg)

  const routeChange = () =>{ 
    let path = `/login`; 
    navigate(path);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Apply lowercase transformation only for the email field
    const updatedValue = name === "email" ? value.toLowerCase() : value;

    // Validate email if the input is for the email field
    if (name === "email") {
        setValidEmail(validator.isEmail(updatedValue));
    }

    // Update credentials
    setCredentials({ ...credentials, [name]: updatedValue });
  };


  const sendEmail = async (e) => {
    e.preventDefault();
    routeChange()
    dispatch(postForgotPassword({ props, credentials }))
  };

  //console.log("postForgotPassword: " + postForgotPassword({email: "caleb.wliams@pdgcreative.com"}))
  

{/*
const checkEmailExists = async () => {
    const response = await fetch(
    `${process.env.REACT_APP_CHECK_EMAIL}${credentials.email}`, {timeout: 8000}
    ).then((response) => response.json({}));
    setResponse(response)
};
*/}


    return (
      <div className="Background-Container" style={backGroundStyle}>
      <Container component="main" maxwidth="xs">
              <CssBaseline />
              <Paper style={loginPaperStyle}>
                  <img src={levlr_logo} style={levlrLogoStyle} alt="Levlr Logo"/>
                  <form style={loginFormStyle} noValidate onSubmit={(e) => sendEmail(e)}>
                  <h1> Forgot Password? </h1>
                  <br></br>
                    <div>Please Enter Your Email</div>
                    <div>Please Note: Emails and Passwords are Case Sensitive</div>
          
          <TextField
            id="email"
             label="email"
             name="email"
             onChange={handleChange}
             placeholder="Email Address"
             style={loginTextFieldStyle}
             error={!validEmail}
             helperText={validEmail ? "":"Please Enter A Valid Email"}
           />
            <Button
                type="submit"
                variant="contained"
                style={loginButtonStyle}
                disabled={!validEmail}
                //onClick={checkEmailExists}
            >
             Send Password Reset Email
            </Button>

                </form>

                <Button
                        type="submit"
                        variant="contained"
                        style={loginButtonStyle}
                        onClick={routeChange}
                    >
                        Return to Login
                    </Button>
              </Paper>
          </Container>
          {/* {showNullError && (
          <div>
            <p>The email address cannot be null.</p>
          </div>
        )} */}
        {/* {showError && (
          <div>
            <p>
              That email address isn&apos;t recognized. Please try again. New user? Sign up here!
            </p>
           
          </div>
        )} */}
        {/* {serverMsg === 'recovery email sent' && (
          <div>
            <h3>Password Reset Email Successfully Sent!</h3>
          </div>
        )}
        */}
      </div>
      


      //  old code
      //   {showNullError && (
      //     <div>
      //       <p>The email address cannot be null.</p>
      //     </div>
      //   )}
      //   {showError && (
      //     <div>
      //       <p>
      //         That email address isn&apos;t recognized. Please try again. New user? Sign up here!
      //       </p>
      //       <Button
      //                   onClick={routeChange}
      //                   variant="contained"
                       
      //               >
      //                   SIGN UP
      //               </Button>
      //     </div>
      //   )}
      //   {serverMsg === 'recovery email sent' && (
      //     <div>
      //       <h3>Password Reset Email Successfully Sent!</h3>
      //     </div>
      //   )}
      //   <button> Return to Login 
      //     {/* link="/" */}
      //      </button>
      // </div>
    );
  }


export default ForgotPassword;


